.btn-custom{
    background-color: #E50914 !important;
    color:#fff;
    border-radius: 25px !important;
}
.btn-custom2{
    background-color: #808080 !important;
    color:#fff;
    border-radius: 25px !important;
}
.custom-input{
    background-color: #fff !important;
    border-radius: 25px !important;
    border: 0px !important;
    color: #000 !important
}
.main-header{
    background-color: #363434 !important;
}
.copyright{
    color: #d1c6c6;
    background-color: #363434;
}
.text-body{
    color:#fff !important
}
.tnc{
    margin-top: 30px;
}
.unsub{
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
}