.btn-custom{
    background-color: #E50914 !important;
    color:#fff;
    border-radius: 25px !important;
}
.btn-custom2{
    background-color: #808080 !important;
    color:#fff;
    border-radius: 25px !important;
}
.custom-input{
    background-color: #fff !important;
    border-radius: 25px !important;
    border: 0px !important;
    color: #000 !important
}
.main-header{
    background-color: #000 !important;
}